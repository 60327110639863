import React from "react";
import Bredcom from "../Bredcrumb/Main";
import Img from "../../assets/images/main-slider/slider-2.jpg";

function Main() {
    return (
        <>
            <Bredcom subtitle="Datenschutz" title="Rechtliches" Img={Img} />

            <div className="auto-container" style={{ margin: "120px auto" }}>
                <h2>Inhaltsübersicht</h2>
                <ul class="index">
                    <li>
                        <a class="index-link" href="#m3">
                            Verantwortlicher
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#mOverview">
                            Übersicht der Verarbeitungen
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m2427">
                            Maßgebliche Rechtsgrundlagen
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m27">
                            Sicherheitsmaßnahmen
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m10">
                            Rechte der betroffenen Personen
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m317">
                            Geschäftliche Leistungen
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m225">
                            Bereitstellung des Onlineangebots und Webhosting
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m182">
                            Kontakt- und Anfrageverwaltung
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m2324">
                            Datenschutzinformationen für Hinweisgeber
                        </a>
                    </li>
                    <li>
                        <a class="index-link" href="#m42">
                            Begriffsdefinitionen
                        </a>
                    </li>
                </ul>
                <h2 id="m3">Verantwortlicher</h2>
                <p>
                    Party Richter Bala GmbH
                    <br />
                    Bahnhofstraße 70,
                    <br />
                    42781 Haan
                </p>
                <p>Vertretungsberechtigte Personen: Avni Bala</p>
                <p>E-Mail-Adresse: info@party-richter-bala.de</p>
                <p>Telefon: 02129 3788003</p>
                <p>
                    Impressum:{" "}
                    <a
                        href="https://www.party-richter-bala.de/impressum"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://www.party-richter-bala.de/impressum
                    </a>
                </p>

                <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
                <p>
                    Die nachfolgende Übersicht fasst die Arten der verarbeiteten
                    Daten und die Zwecke ihrer Verarbeitung zusammen und
                    verweist auf die betroffenen Personen.
                </p>
                <h3>Arten der verarbeiteten Daten</h3>
                <ul>
                    <li>Bestandsdaten.</li>
                    <li>Beschäftigtendaten.</li>
                    <li>Zahlungsdaten.</li>
                    <li>Kontaktdaten.</li>
                    <li>Inhaltsdaten.</li>
                    <li>Vertragsdaten.</li>
                    <li>Nutzungsdaten.</li>
                    <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
                    <li>Protokolldaten.</li>
                </ul>
                <h3>Kategorien betroffener Personen</h3>
                <ul>
                    <li>Leistungsempfänger und Auftraggeber.</li>
                    <li>Beschäftigte.</li>
                    <li>Interessenten.</li>
                    <li>Kommunikationspartner.</li>
                    <li>Nutzer.</li>
                    <li>Geschäfts- und Vertragspartner.</li>
                    <li>Dritte Personen.</li>
                    <li>Hinweisgeber.</li>
                </ul>
                <h3>Zwecke der Verarbeitung</h3>
                <ul>
                    <li>
                        Erbringung vertraglicher Leistungen und Erfüllung
                        vertraglicher Pflichten.
                    </li>
                    <li>Kommunikation.</li>
                    <li>Sicherheitsmaßnahmen.</li>
                    <li>Büro- und Organisationsverfahren.</li>
                    <li>Organisations- und Verwaltungsverfahren.</li>
                    <li>Feedback.</li>
                    <li>
                        Bereitstellung unseres Onlineangebotes und
                        Nutzerfreundlichkeit.
                    </li>
                    <li>Informationstechnische Infrastruktur.</li>
                    <li>Hinweisgeberschutz.</li>
                    <li>
                        Geschäftsprozesse und betriebswirtschaftliche Verfahren.
                    </li>
                </ul>
                <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2>
                <p>
                    <strong>
                        Maßgebliche Rechtsgrundlagen nach der DSGVO:{" "}
                    </strong>
                    Im Folgenden erhalten Sie eine Übersicht der
                    Rechtsgrundlagen der DSGVO, auf deren Basis wir
                    personenbezogene Daten verarbeiten. Bitte nehmen Sie zur
                    Kenntnis, dass neben den Regelungen der DSGVO nationale
                    Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder
                    Sitzland gelten können. Sollten ferner im Einzelfall
                    speziellere Rechtsgrundlagen maßgeblich sein, teilen wir
                    Ihnen diese in der Datenschutzerklärung mit.
                </p>
                <ul>
                    <li>
                        <strong>
                            Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)
                        </strong>{" "}
                        - Die betroffene Person hat ihre Einwilligung in die
                        Verarbeitung der sie betreffenden personenbezogenen
                        Daten für einen spezifischen Zweck oder mehrere
                        bestimmte Zwecke gegeben.
                    </li>
                    <li>
                        <strong>
                            Vertragserfüllung und vorvertragliche Anfragen (Art.
                            6 Abs. 1 S. 1 lit. b) DSGVO)
                        </strong>{" "}
                        - Die Verarbeitung ist für die Erfüllung eines Vertrags,
                        dessen Vertragspartei die betroffene Person ist, oder
                        zur Durchführung vorvertraglicher Maßnahmen
                        erforderlich, die auf Anfrage der betroffenen Person
                        erfolgen.
                    </li>
                    <li>
                        <strong>
                            Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c)
                            DSGVO)
                        </strong>{" "}
                        - Die Verarbeitung ist zur Erfüllung einer rechtlichen
                        Verpflichtung erforderlich, der der Verantwortliche
                        unterliegt.
                    </li>
                    <li>
                        <strong>
                            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
                            DSGVO)
                        </strong>{" "}
                        - die Verarbeitung ist zur Wahrung der berechtigten
                        Interessen des Verantwortlichen oder eines Dritten
                        notwendig, vorausgesetzt, dass die Interessen,
                        Grundrechte und Grundfreiheiten der betroffenen Person,
                        die den Schutz personenbezogener Daten verlangen, nicht
                        überwiegen.
                    </li>
                </ul>
                <p>
                    <strong>
                        Nationale Datenschutzregelungen in Deutschland:{" "}
                    </strong>
                    Zusätzlich zu den Datenschutzregelungen der DSGVO gelten
                    nationale Regelungen zum Datenschutz in Deutschland. Hierzu
                    gehört insbesondere das Gesetz zum Schutz vor Missbrauch
                    personenbezogener Daten bei der Datenverarbeitung
                    (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
                    insbesondere Spezialregelungen zum Recht auf Auskunft, zum
                    Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung
                    besonderer Kategorien personenbezogener Daten, zur
                    Verarbeitung für andere Zwecke und zur Übermittlung sowie
                    automatisierten Entscheidungsfindung im Einzelfall
                    einschließlich Profiling. Ferner können
                    Landesdatenschutzgesetze der einzelnen Bundesländer zur
                    Anwendung gelangen.
                </p>

                <h2 id="m27">Sicherheitsmaßnahmen</h2>
                <p>
                    Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
                    Berücksichtigung des Stands der Technik, der
                    Implementierungskosten und der Art, des Umfangs, der
                    Umstände und der Zwecke der Verarbeitung sowie der
                    unterschiedlichen Eintrittswahrscheinlichkeiten und des
                    Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher
                    Personen geeignete technische und organisatorische
                    Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu
                    gewährleisten.
                </p>
                <p>
                    Zu den Maßnahmen gehören insbesondere die Sicherung der
                    Vertraulichkeit, Integrität und Verfügbarkeit von Daten
                    durch Kontrolle des physischen und elektronischen Zugangs zu
                    den Daten als auch des sie betreffenden Zugriffs, der
                    Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und
                    ihrer Trennung. Des Weiteren haben wir Verfahren
                    eingerichtet, die eine Wahrnehmung von Betroffenenrechten,
                    die Löschung von Daten und Reaktionen auf die Gefährdung der
                    Daten gewährleisten. Ferner berücksichtigen wir den Schutz
                    personenbezogener Daten bereits bei der Entwicklung bzw.
                    Auswahl von Hardware, Software sowie Verfahren entsprechend
                    dem Prinzip des Datenschutzes, durch Technikgestaltung und
                    durch datenschutzfreundliche Voreinstellungen.
                </p>
                <p>
                    Sicherung von Online-Verbindungen durch
                    TLS-/SSL-Verschlüsselungstechnologie (HTTPS): Um die Daten
                    der Nutzer, die über unsere Online-Dienste übertragen
                    werden, vor unerlaubten Zugriffen zu schützen, setzen wir
                    auf die TLS-/SSL-Verschlüsselungstechnologie. Secure Sockets
                    Layer (SSL) und Transport Layer Security (TLS) sind die
                    Eckpfeiler der sicheren Datenübertragung im Internet. Diese
                    Technologien verschlüsseln die Informationen, die zwischen
                    der Website oder App und dem Browser des Nutzers (oder
                    zwischen zwei Servern) übertragen werden, wodurch die Daten
                    vor unbefugtem Zugriff geschützt sind. TLS, als die
                    weiterentwickelte und sicherere Version von SSL,
                    gewährleistet, dass alle Datenübertragungen den höchsten
                    Sicherheitsstandards entsprechen. Wenn eine Website durch
                    ein SSL-/TLS-Zertifikat gesichert ist, wird dies durch die
                    Anzeige von HTTPS in der URL signalisiert. Dies dient als
                    ein Indikator für die Nutzer, dass ihre Daten sicher und
                    verschlüsselt übertragen werden.
                </p>

                <h2 id="m10">Rechte der betroffenen Personen</h2>
                <p>
                    Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen
                    als Betroffene nach der DSGVO verschiedene Rechte zu, die
                    sich insbesondere aus Art. 15 bis 21 DSGVO ergeben:
                </p>
                <ul>
                    <li>
                        <strong>
                            Widerspruchsrecht: Sie haben das Recht, aus Gründen,
                            die sich aus Ihrer besonderen Situation ergeben,
                            jederzeit gegen die Verarbeitung der Sie
                            betreffenden personenbezogenen Daten, die aufgrund
                            von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
                            Widerspruch einzulegen; dies gilt auch für ein auf
                            diese Bestimmungen gestütztes Profiling. Werden die
                            Sie betreffenden personenbezogenen Daten
                            verarbeitet, um Direktwerbung zu betreiben, haben
                            Sie das Recht, jederzeit Widerspruch gegen die
                            Verarbeitung der Sie betreffenden personenbezogenen
                            Daten zum Zwecke derartiger Werbung einzulegen; dies
                            gilt auch für das Profiling, soweit es mit solcher
                            Direktwerbung in Verbindung steht.
                        </strong>
                    </li>
                    <li>
                        <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie
                        haben das Recht, erteilte Einwilligungen jederzeit zu
                        widerrufen.
                    </li>
                    <li>
                        <strong>Auskunftsrecht:</strong> Sie haben das Recht,
                        eine Bestätigung darüber zu verlangen, ob betreffende
                        Daten verarbeitet werden und auf Auskunft über diese
                        Daten sowie auf weitere Informationen und Kopie der
                        Daten entsprechend den gesetzlichen Vorgaben.
                    </li>
                    <li>
                        <strong>Recht auf Berichtigung:</strong> Sie haben
                        entsprechend den gesetzlichen Vorgaben das Recht, die
                        Vervollständigung der Sie betreffenden Daten oder die
                        Berichtigung der Sie betreffenden unrichtigen Daten zu
                        verlangen.
                    </li>
                    <li>
                        <strong>
                            Recht auf Löschung und Einschränkung der
                            Verarbeitung:
                        </strong>{" "}
                        Sie haben nach Maßgabe der gesetzlichen Vorgaben das
                        Recht, zu verlangen, dass Sie betreffende Daten
                        unverzüglich gelöscht werden, bzw. alternativ nach
                        Maßgabe der gesetzlichen Vorgaben eine Einschränkung der
                        Verarbeitung der Daten zu verlangen.
                    </li>
                    <li>
                        <strong>Recht auf Datenübertragbarkeit:</strong> Sie
                        haben das Recht, Sie betreffende Daten, die Sie uns
                        bereitgestellt haben, nach Maßgabe der gesetzlichen
                        Vorgaben in einem strukturierten, gängigen und
                        maschinenlesbaren Format zu erhalten oder deren
                        Übermittlung an einen anderen Verantwortlichen zu
                        fordern.
                    </li>
                    <li>
                        <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie
                        haben unbeschadet eines anderweitigen
                        verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs
                        das Recht auf Beschwerde bei einer Aufsichtsbehörde,
                        insbesondere in dem Mitgliedstaat ihres gewöhnlichen
                        Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
                        mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass
                        die Verarbeitung der Sie betreffenden personenbezogenen
                        Daten gegen die Vorgaben der DSGVO verstößt.
                    </li>
                </ul>

                <h2 id="m317">Geschäftliche Leistungen</h2>
                <p>
                    Wir verarbeiten Daten unserer Vertrags- und
                    Geschäftspartner, z. B. Kunden und Interessenten
                    (zusammenfassend als „Vertragspartner" bezeichnet), im
                    Rahmen von vertraglichen und vergleichbaren
                    Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im
                    Hinblick auf die Kommunikation mit den Vertragspartnern
                    (oder vorvertraglich), etwa zur Beantwortung von Anfragen.
                </p>
                <p>
                    Wir verwenden diese Daten, um unsere vertraglichen
                    Verpflichtungen zu erfüllen. Dazu gehören insbesondere die
                    Pflichten zur Erbringung der vereinbarten Leistungen,
                    etwaige Aktualisierungspflichten und Abhilfe bei
                    Gewährleistungs- und sonstigen Leistungsstörungen. Darüber
                    hinaus verwenden wir die Daten zur Wahrung unserer Rechte
                    und zum Zwecke der mit diesen Pflichten verbundenen
                    Verwaltungsaufgaben sowie der Unternehmensorganisation.
                    Zudem verarbeiten wir die Daten auf Grundlage unserer
                    berechtigten Interessen sowohl an einer ordnungsgemäßen und
                    betriebswirtschaftlichen Geschäftsführung als auch an
                    Sicherheitsmaßnahmen zum Schutz unserer Vertragspartner und
                    unseres Geschäftsbetriebs vor Missbrauch, Gefährdung ihrer
                    Daten, Geheimnisse, Informationen und Rechte (z. B. zur
                    Beteiligung von Telekommunikations-, Transport- und
                    sonstigen Hilfsdiensten sowie Subunternehmern, Banken,
                    Steuer- und Rechtsberatern, Zahlungsdienstleistern oder
                    Finanzbehörden). Im Rahmen des geltenden Rechts geben wir
                    die Daten von Vertragspartnern nur insoweit an Dritte
                    weiter, als dies für die vorgenannten Zwecke oder zur
                    Erfüllung gesetzlicher Pflichten erforderlich ist. Über
                    weitere Formen der Verarbeitung, etwa zu Marketingzwecken,
                    werden die Vertragspartner im Rahmen dieser
                    Datenschutzerklärung informiert.
                </p>
                <p>
                    Welche Daten für die vorgenannten Zwecke erforderlich sind,
                    teilen wir den Vertragspartnern vor oder im Rahmen der
                    Datenerhebung, z. B. in Onlineformularen, durch besondere
                    Kennzeichnung (z. B. Farben) bzw. Symbole (z. B. Sternchen
                    o. Ä.), oder persönlich mit.
                </p>
                <p>
                    Wir löschen die Daten nach Ablauf gesetzlicher
                    Gewährleistungs- und vergleichbarer Pflichten, d. h.
                    grundsätzlich nach vier Jahren, es sei denn, dass die Daten
                    in einem Kundenkonto gespeichert werden, z. B., solange sie
                    aus gesetzlichen Gründen der Archivierung aufbewahrt werden
                    müssen (etwa für Steuerzwecke im Regelfall zehn Jahre).
                    Daten, die uns im Rahmen eines Auftrags durch den
                    Vertragspartner offengelegt wurden, löschen wir entsprechend
                    den Vorgaben und grundsätzlich nach Ende des Auftrags.
                </p>
                <ul class="m-elements">
                    <li>
                        <strong>Verarbeitete Datenarten:</strong> Bestandsdaten
                        (z. B. der vollständige Name, Wohnadresse,
                        Kontaktinformationen, Kundennummer, etc.); Zahlungsdaten
                        (z. B. Bankverbindungen, Rechnungen, Zahlungshistorie);
                        Kontaktdaten (z. B. Post- und E-Mail-Adressen oder
                        Telefonnummern). Vertragsdaten (z. B.
                        Vertragsgegenstand, Laufzeit, Kundenkategorie).
                    </li>
                    <li>
                        <strong>Betroffene Personen:</strong> Leistungsempfänger
                        und Auftraggeber; Interessenten. Geschäfts- und
                        Vertragspartner.
                    </li>
                    <li>
                        <strong>Zwecke der Verarbeitung:</strong> Erbringung
                        vertraglicher Leistungen und Erfüllung vertraglicher
                        Pflichten; Kommunikation; Büro- und
                        Organisationsverfahren; Organisations- und
                        Verwaltungsverfahren. Geschäftsprozesse und
                        betriebswirtschaftliche Verfahren.
                    </li>
                    <li>
                        <strong>Aufbewahrung und Löschung:</strong> Löschung
                        entsprechend Angaben im Abschnitt "Allgemeine
                        Informationen zur Datenspeicherung und Löschung".
                    </li>
                    <li class="">
                        <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
                        vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b)
                        DSGVO); Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1
                        lit. c) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S.
                        1 lit. f) DSGVO).
                    </li>
                </ul>
                <p>
                    <strong>
                        Weitere Hinweise zu Verarbeitungsprozessen, Verfahren
                        und Diensten:
                    </strong>
                </p>
                <ul class="m-elements">
                    <li>
                        <strong>Eventmanagement: </strong>Wir verarbeiten die
                        Daten der Teilnehmer der von uns angebotenen oder
                        ausgerichteten Veranstaltungen, Events und ähnlichen
                        Aktivitäten (nachfolgend einheitlich als "Teilnehmer"
                        und „Veranstaltungen" bezeichnet), um ihnen die
                        Teilnahme an den Veranstaltungen und Inanspruchnahme der
                        mit der Teilnahme verbundenen Leistungen oder Aktionen
                        zu ermöglichen. <br />
                        <br />
                        Sofern wir in diesem Rahmen gesundheitsbezogene Daten,
                        religiöse, politische oder sonstige besondere Kategorien
                        von Daten verarbeiten, dann erfolgt diese im Rahmen der
                        Offenkundigkeit (z. B. bei thematisch ausgerichteten
                        Veranstaltungen oder dient der Gesundheitsvorsorge,
                        Sicherheit oder erfolgt mit Einwilligung der
                        Betroffenen).
                        <br />
                        <br />
                        Die erforderlichen Angaben sind als solche im Rahmen des
                        Auftrags-, Bestell- bzw. vergleichbaren
                        Vertragsschlusses gekennzeichnet und umfassen die zur
                        Leistungserbringung und Abrechnung benötigten Angaben
                        sowie Kontaktinformationen, um etwaige Rücksprachen
                        halten zu können. Soweit wir Zugang zu Informationen der
                        Endkunden, Mitarbeitern oder anderer Personen erhalten,
                        verarbeiten wir diese im Einklang mit den gesetzlichen
                        und vertraglichen Vorgaben;{" "}
                        <span class="">
                            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung
                            und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
                            lit. b) DSGVO).
                        </span>
                    </li>
                </ul>
                <h2 id="m225">
                    Bereitstellung des Onlineangebots und Webhosting
                </h2>
                <p>
                    Wir verarbeiten die Daten der Nutzer, um ihnen unsere
                    Online-Dienste zur Verfügung stellen zu können. Zu diesem
                    Zweck verarbeiten wir die IP-Adresse des Nutzers, die
                    notwendig ist, um die Inhalte und Funktionen unserer
                    Online-Dienste an den Browser oder das Endgerät der Nutzer
                    zu übermitteln.
                </p>
                <ul class="m-elements">
                    <li>
                        <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten
                        (z. B. Seitenaufrufe und Verweildauer, Klickpfade,
                        Nutzungsintensität und -frequenz, verwendete Gerätetypen
                        und Betriebssysteme, Interaktionen mit Inhalten und
                        Funktionen); Meta-, Kommunikations- und Verfahrensdaten
                        (z. B. IP-Adressen, Zeitangaben, Identifikationsnummern,
                        beteiligte Personen). Protokolldaten (z. B. Logfiles
                        betreffend Logins oder den Abruf von Daten oder
                        Zugriffszeiten.).
                    </li>
                    <li>
                        <strong>Betroffene Personen:</strong> Nutzer (z. B.
                        Webseitenbesucher, Nutzer von Onlinediensten).
                    </li>
                    <li>
                        <strong>Zwecke der Verarbeitung:</strong> Bereitstellung
                        unseres Onlineangebotes und Nutzerfreundlichkeit;
                        Informationstechnische Infrastruktur (Betrieb und
                        Bereitstellung von Informationssystemen und technischen
                        Geräten (Computer, Server etc.).). Sicherheitsmaßnahmen.
                    </li>
                    <li>
                        <strong>Aufbewahrung und Löschung:</strong> Löschung
                        entsprechend Angaben im Abschnitt "Allgemeine
                        Informationen zur Datenspeicherung und Löschung".
                    </li>
                    <li class="">
                        <strong>Rechtsgrundlagen:</strong> Berechtigte
                        Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                    </li>
                </ul>
                <p>
                    <strong>
                        Weitere Hinweise zu Verarbeitungsprozessen, Verfahren
                        und Diensten:
                    </strong>
                </p>
                <ul class="m-elements">
                    <li>
                        <strong>
                            Erhebung von Zugriffsdaten und Logfiles:{" "}
                        </strong>
                        Der Zugriff auf unser Onlineangebot wird in Form von
                        sogenannten "Server-Logfiles" protokolliert. Zu den
                        Serverlogfiles können die Adresse und der Name der
                        abgerufenen Webseiten und Dateien, Datum und Uhrzeit des
                        Abrufs, übertragene Datenmengen, Meldung über
                        erfolgreichen Abruf, Browsertyp nebst Version, das
                        Betriebssystem des Nutzers, Referrer URL (die zuvor
                        besuchte Seite) und im Regelfall IP-Adressen und der
                        anfragende Provider gehören. Die Serverlogfiles können
                        zum einen zu Sicherheitszwecken eingesetzt werden, z. B.
                        um eine Überlastung der Server zu vermeiden
                        (insbesondere im Fall von missbräuchlichen Angriffen,
                        sogenannten DDoS-Attacken), und zum anderen, um die
                        Auslastung der Server und ihre Stabilität
                        sicherzustellen;{" "}
                        <span class="">
                            <strong>Rechtsgrundlagen:</strong> Berechtigte
                            Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).{" "}
                        </span>
                        <strong>Löschung von Daten:</strong>{" "}
                        Logfile-Informationen werden für die Dauer von maximal
                        30 Tagen gespeichert und danach gelöscht oder
                        anonymisiert. Daten, deren weitere Aufbewahrung zu
                        Beweiszwecken erforderlich ist, sind bis zur endgültigen
                        Klärung des jeweiligen Vorfalls von der Löschung
                        ausgenommen.
                    </li>
                    <li>
                        <strong>Content-Delivery-Network: </strong>Wir setzen
                        ein "Content-Delivery-Network" (CDN) ein. Ein CDN ist
                        ein Dienst, mit dessen Hilfe Inhalte eines
                        Onlineangebotes, insbesondere große Mediendateien, wie
                        Grafiken oder Programm-Skripte, mit Hilfe regional
                        verteilter und über das Internet verbundener Server
                        schneller und sicherer ausgeliefert werden können;{" "}
                        <span class="">
                            <strong>Rechtsgrundlagen:</strong> Berechtigte
                            Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                        </span>
                    </li>
                </ul>
                <h2 id="m182">Kontakt- und Anfrageverwaltung</h2>
                <p>
                    Bei der Kontaktaufnahme mit uns (z. B. per Post,
                    Kontaktformular, E-Mail, Telefon oder via soziale Medien)
                    sowie im Rahmen bestehender Nutzer- und Geschäftsbeziehungen
                    werden die Angaben der anfragenden Personen verarbeitet,
                    soweit dies zur Beantwortung der Kontaktanfragen und
                    etwaiger angefragter Maßnahmen erforderlich ist.
                </p>
                <ul class="m-elements">
                    <li>
                        <strong>Verarbeitete Datenarten:</strong> Bestandsdaten
                        (z. B. der vollständige Name, Wohnadresse,
                        Kontaktinformationen, Kundennummer, etc.); Kontaktdaten
                        (z. B. Post- und E-Mail-Adressen oder Telefonnummern);
                        Inhaltsdaten (z. B. textliche oder bildliche Nachrichten
                        und Beiträge sowie die sie betreffenden Informationen,
                        wie z. B. Angaben zur Autorenschaft oder Zeitpunkt der
                        Erstellung); Nutzungsdaten (z. B. Seitenaufrufe und
                        Verweildauer, Klickpfade, Nutzungsintensität und
                        -frequenz, verwendete Gerätetypen und Betriebssysteme,
                        Interaktionen mit Inhalten und Funktionen). Meta-,
                        Kommunikations- und Verfahrensdaten (z. B. IP-Adressen,
                        Zeitangaben, Identifikationsnummern, beteiligte
                        Personen).
                    </li>
                    <li>
                        <strong>Betroffene Personen:</strong>{" "}
                        Kommunikationspartner.
                    </li>
                    <li>
                        <strong>Zwecke der Verarbeitung:</strong> Kommunikation;
                        Organisations- und Verwaltungsverfahren; Feedback (z. B.
                        Sammeln von Feedback via Online-Formular).
                        Bereitstellung unseres Onlineangebotes und
                        Nutzerfreundlichkeit.
                    </li>
                    <li>
                        <strong>Aufbewahrung und Löschung:</strong> Löschung
                        entsprechend Angaben im Abschnitt "Allgemeine
                        Informationen zur Datenspeicherung und Löschung".
                    </li>
                    <li class="">
                        <strong>Rechtsgrundlagen:</strong> Berechtigte
                        Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                        Vertragserfüllung und vorvertragliche Anfragen (Art. 6
                        Abs. 1 S. 1 lit. b) DSGVO).
                    </li>
                </ul>
                <p>
                    <strong>
                        Weitere Hinweise zu Verarbeitungsprozessen, Verfahren
                        und Diensten:
                    </strong>
                </p>
                <ul class="m-elements">
                    <li>
                        <strong>Kontaktformular: </strong>Bei Kontaktaufnahme
                        über unser Kontaktformular, per E-Mail oder anderen
                        Kommunikationswegen, verarbeiten wir die uns
                        übermittelten personenbezogenen Daten zur Beantwortung
                        und Bearbeitung des jeweiligen Anliegens. Dies umfasst
                        in der Regel Angaben wie Name, Kontaktinformationen und
                        gegebenenfalls weitere Informationen, die uns mitgeteilt
                        werden und zur angemessenen Bearbeitung erforderlich
                        sind. Wir nutzen diese Daten ausschließlich für den
                        angegebenen Zweck der Kontaktaufnahme und Kommunikation;{" "}
                        <span class="">
                            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung
                            und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
                            lit. b) DSGVO), Berechtigte Interessen (Art. 6 Abs.
                            1 S. 1 lit. f) DSGVO).
                        </span>
                    </li>
                </ul>
                <h2 id="m2324">Datenschutzinformationen für Hinweisgeber</h2>
                <p>
                    In diesem Abschnitt finden Sie Informationen darüber, wie
                    wir Daten von Personen, die Hinweise geben (Hinweisgeber),
                    sowie von betroffenen und beteiligten Parteien im Rahmen
                    unseres Hinweisgeberverfahrens handhaben. Unser Ziel ist es,
                    eine unkomplizierte und sichere Möglichkeit zur Meldung von
                    möglichem Fehlverhalten durch uns, unsere Mitarbeiter oder
                    Dienstleister zu bieten, insbesondere für Handlungen, die
                    gegen Gesetze oder
                    <span
                        class="dsg-license-content-blurred de dsg-ttip-activate"
                        title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."
                    >
                        {" "}
                        - Dieser Textbereich muss mit einer Premium Lizenz
                        freischaltet werden. - premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext{" "}
                    </span>
                </p>
                <p>
                    Rechtsgrundlagen (Deutschland): Soweit wir Daten zur
                    Erfüllung unserer gesetzlichen Pflichten entsprechend dem
                    Hinweisgeberschutzgesetz (HinSchG) verarbeiten, ist die
                    rechtliche Basis der Verarbeitung Artikel 6 Abs. 1 S. 1 lit.
                    c) DSGVO und im Fall besonderer Kategorien personenbezogener
                    Daten Art. 9 Abs. 2 lit. g) DSGVO, § 22 BDSG, jeweils in
                    Verbindung mit § 10 HinSchG. Dies bezieht sich auf die
                    Verpflichtung zur Einrichtung und zum Betrieb einer internen
                    Hinweisgebermeldestelle, die Erfüllung ihrer gesetzlichen
                    Aufgaben und, im Fall der Nutzung der im Meldeverfahren
                    erhobenen Daten, das Ergreifen weiterer Untersuchungen oder
                    arbeitsrechtlicher Schritte gegen Personen, die eines
                    Verstoßes überführt wurden.
                </p>
                <p>
                    Soweit wir Daten (insbesondere bei festgestelltem
                    Fehlverhalten) im Rahmen oder zur Vorbereitung der
                    Rechtsverteidigung verarbeiten, erfolgt dies auf Grundlage
                    unserer berechtigten Interessen an einem rechtskonformen und
                    ethischen Handeln gemäß Art. 6 Abs. 1 S. 1 lit. f) DSGVO.
                </p>
                <p>
                    Soweit Sie uns eine Einwilligung zur Verarbeitung von
                    personenbezogenen Daten für bestimmte Zwecke erteilt wurde,
                    erfolgt die Verarbeitung auf deren Grundlage gem. Art. 6
                    Abs. 1 S. 1 lit. a) DSGVO und im Fall besonderer
                    <span
                        class="dsg-license-content-blurred de dsg-ttip-activate"
                        title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."
                    >
                        {" "}
                        - Dieser Textbereich muss mit einer Premium Lizenz
                        freischaltet werden. - premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext{" "}
                    </span>
                </p>
                <p>
                    Rechtsgrundlagen (Österreich): Soweit wir Daten zur
                    Erfüllung unserer gesetzlichen Pflichten entsprechend dem
                    HinweisgeberInnenschutzgesetz (HSchG) verarbeiten, ist die
                    rechtliche Basis der Verarbeitung Artikel 6 Abs. 1 S. 1 lit.
                    c) DSGVO und im Fall besonderer Kategorien personenbezogener
                    Daten Art. 9 Abs. 2 lit. g) DSGVO, jeweils in Verbindung mit
                    § 8 HSchG. Dies bezieht sich auf die Verpflichtung zur
                    Einrichtung und zum Betrieb einer internen
                    Hinweisgebermeldestelle, die Erfüllung ihrer gesetzlichen
                    Aufgaben und, im Fall der Nutzung der im Meldeverfahren
                    erhobenen Daten, das Ergreifen weiterer Untersuchungen oder
                    arbeitsrechtlicher Schritte gegen Personen, die eines
                    Verstoßes überführt wurden.
                </p>
                <p>
                    Soweit wir Daten (insbesondere bei festgestelltem
                    Fehlverhalten) im Rahmen oder zur Vorbereitung der
                    Rechtsverteidigung verarbeiten, erfolgt dies auf Grundlage
                    unserer berechtigten Interessen an einem rechtskonformen und
                    ethischen Handeln gemäß Art. 6 Abs. 1 S. 1 lit. f) DSGVO.
                </p>
                <p>
                    Soweit Sie uns eine Einwilligung zur Verarbeitung von
                    personenbezogenen Daten für bestimmte Zwecke erteilt wurde,
                    erfolgt die Verarbeitung auf deren Grundlage gem. Art. 6
                    Abs. 1 S. 1 lit. a) DSGVO und im Fall besonderer Kategorien
                    <span
                        class="dsg-license-content-blurred de dsg-ttip-activate"
                        title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."
                    >
                        {" "}
                        - Dieser Textbereich muss mit einer Premium Lizenz
                        freischaltet werden. - premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext
                        premiumtext premiumtext premiumtext premiumtext{" "}
                    </span>
                </p>
                <ul class="m-elements">
                    <li>
                        <strong>Verarbeitete Datenarten:</strong> Bestandsdaten
                        (z. B. der vollständige Name, Wohnadresse,
                        Kontaktinformationen, Kundennummer,
                        <span
                            class="dsg-license-content-blurred de dsg-ttip-activate"
                            title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."
                        >
                            {" "}
                            - Dieser Textbereich muss mit einer Premium Lizenz
                            freischaltet werden. - premiumtext premiumtext
                            premiumtext{" "}
                        </span>
                        ); Beschäftigtendaten (Informationen zu Mitarbeitern und
                        anderen Personen in einem
                        <span
                            class="dsg-license-content-blurred de dsg-ttip-activate"
                            title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."
                        >
                            {" "}
                            - Dieser Textbereich muss mit einer Premium Lizenz
                            freischaltet werden. - premiumtext premiumtext
                            premiumtext{" "}
                        </span>
                        ); Kontaktdaten (z. B. Post- und E-Mail-Adressen oder
                        <span
                            class="dsg-license-content-blurred de dsg-ttip-activate"
                            title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."
                        >
                            {" "}
                            - Dieser Textbereich muss mit einer Premium Lizenz
                            freischaltet werden. - premiumtext premiumtext
                            premiumtext{" "}
                        </span>
                        ); Inhaltsdaten (z. B. textliche oder bildliche
                        Nachrichten und Beiträge sowie die sie betreffenden
                        Informationen, wie z. B. Angaben zur Autorenschaft
                        <span
                            class="dsg-license-content-blurred de dsg-ttip-activate"
                            title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."
                        >
                            {" "}
                            - Dieser Textbereich muss mit einer Premium Lizenz
                            freischaltet werden. - premiumtext premiumtext
                            premiumtext premiumtext premiumtext premiumtext{" "}
                        </span>
                        ). Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer,
                        Klickpfade, Nutzungsintensität und -frequenz, verwendete
                        Gerätetypen und Betriebssysteme, Interaktionen mit
                        <span
                            class="dsg-license-content-blurred de dsg-ttip-activate"
                            title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."
                        >
                            {" "}
                            - Dieser Textbereich muss mit einer Premium Lizenz
                            freischaltet werden. - premiumtext premiumtext
                            premiumtext premiumtext premiumtext{" "}
                        </span>
                        ).
                    </li>
                    <li>
                        <strong>Betroffene Personen:</strong> Beschäftigte
                        (z. B. Angestellte, Bewerber, Aushilfskräfte und
                        sonstige
                        <span
                            class="dsg-license-content-blurred de dsg-ttip-activate"
                            title="Bitte erwerben Sie eine Lizenz, um die Texte freizuschalten."
                        >
                            {" "}
                            - Dieser Textbereich muss mit einer Premium Lizenz
                            freischaltet werden. - premiumtext premiumtext
                            premiumtext{" "}
                        </span>
                        ); Dritte Personen. Hinweisgeber.
                    </li>
                    <li>
                        <strong>Zwecke der Verarbeitung:</strong>{" "}
                        Hinweisgeberschutz.
                    </li>
                    <li>
                        <strong>Aufbewahrung und Löschung:</strong> Löschung
                        entsprechend Angaben im Abschnitt "Allgemeine
                        Informationen zur Datenspeicherung und Löschung".
                    </li>
                    <li class="">
                        <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6
                        Abs. 1 S. 1 lit. a) DSGVO); Rechtliche Verpflichtung
                        (Art. 6 Abs. 1 S. 1 lit. c) DSGVO). Berechtigte
                        Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                    </li>
                </ul>
                <h2 id="m42">Begriffsdefinitionen</h2>
                <p>
                    In diesem Abschnitt erhalten Sie eine Übersicht über die in
                    dieser Datenschutzerklärung verwendeten Begrifflichkeiten.
                    Soweit die Begrifflichkeiten gesetzlich definiert sind,
                    gelten deren gesetzliche Definitionen. Die nachfolgenden
                    Erläuterungen sollen dagegen vor allem dem Verständnis
                    dienen.
                </p>
                <ul class="glossary">
                    <li>
                        <strong>Beschäftigte:</strong> Als Beschäftigte werden
                        Personen bezeichnet, die in einem
                        Beschäftigungsverhältnis stehen, sei es als Mitarbeiter,
                        Angestellte oder in ähnlichen Positionen. Ein
                        Beschäftigungsverhältnis ist eine rechtliche Beziehung
                        zwischen einem Arbeitgeber und einem Arbeitnehmer, die
                        durch einen Arbeitsvertrag oder eine Vereinbarung
                        festgelegt wird. Es beinhaltet die Verpflichtung des
                        Arbeitgebers, dem Arbeitnehmer eine Vergütung zu zahlen,
                        während der Arbeitnehmer seine Arbeitsleistung erbringt.
                        Das Beschäftigungsverhältnis umfasst verschiedene
                        Phasen, darunter die Begründung, in der der
                        Arbeitsvertrag geschlossen wird, die Durchführung, in
                        der der Arbeitnehmer seine Arbeitstätigkeit ausübt, und
                        die Beendigung, wenn das Beschäftigungsverhältnis endet,
                        sei es durch Kündigung, Aufhebungsvertrag oder
                        anderweitig. Beschäftigtendaten sind alle Informationen,
                        die sich auf diese Personen beziehen und im Kontext
                        ihrer Beschäftigung stehen. Dies umfasst Aspekte wie
                        persönliche Identifikationsdaten,
                        Identifikationsnummern, Gehalts- und Bankdaten,
                        Arbeitszeiten, Urlaubsansprüche, Gesundheitsdaten und
                        Leistungsbeurteilungen.{" "}
                    </li>
                    <li>
                        <strong>Bestandsdaten:</strong> Bestandsdaten umfassen
                        wesentliche Informationen, die für die Identifikation
                        und Verwaltung von Vertragspartnern, Benutzerkonten,
                        Profilen und ähnlichen Zuordnungen notwendig sind. Diese
                        Daten können u.a. persönliche und demografische Angaben
                        wie Namen, Kontaktinformationen (Adressen,
                        Telefonnummern, E-Mail-Adressen), Geburtsdaten und
                        spezifische Identifikatoren (Benutzer-IDs) beinhalten.
                        Bestandsdaten bilden die Grundlage für jegliche formelle
                        Interaktion zwischen Personen und Diensten,
                        Einrichtungen oder Systemen, indem sie eine eindeutige
                        Zuordnung und Kommunikation ermöglichen.{" "}
                    </li>
                    <li>
                        <strong>Inhaltsdaten:</strong> Inhaltsdaten umfassen
                        Informationen, die im Zuge der Erstellung, Bearbeitung
                        und Veröffentlichung von Inhalten aller Art generiert
                        werden. Diese Kategorie von Daten kann Texte, Bilder,
                        Videos, Audiodateien und andere multimediale Inhalte
                        einschließen, die auf verschiedenen Plattformen und
                        Medien veröffentlicht werden. Inhaltsdaten sind nicht
                        nur auf den eigentlichen Inhalt beschränkt, sondern
                        beinhalten auch Metadaten, die Informationen über den
                        Inhalt selbst liefern, wie Tags, Beschreibungen,
                        Autoreninformationen und Veröffentlichungsdaten{" "}
                    </li>
                    <li>
                        <strong>Kontaktdaten:</strong> Kontaktdaten sind
                        essentielle Informationen, die die Kommunikation mit
                        Personen oder Organisationen ermöglichen. Sie umfassen
                        u.a. Telefonnummern, postalische Adressen und
                        E-Mail-Adressen, sowie Kommunikationsmittel wie soziale
                        Medien-Handles und Instant-Messaging-Identifikatoren.{" "}
                    </li>
                    <li>
                        <strong>
                            Meta-, Kommunikations- und Verfahrensdaten:
                        </strong>{" "}
                        Meta-, Kommunikations- und Verfahrensdaten sind
                        Kategorien, die Informationen über die Art und Weise
                        enthalten, wie Daten verarbeitet, übermittelt und
                        verwaltet werden. Meta-Daten, auch bekannt als Daten
                        über Daten, umfassen Informationen, die den Kontext, die
                        Herkunft und die Struktur anderer Daten beschreiben. Sie
                        können Angaben zur Dateigröße, dem Erstellungsdatum, dem
                        Autor eines Dokuments und den Änderungshistorien
                        beinhalten. Kommunikationsdaten erfassen den Austausch
                        von Informationen zwischen Nutzern über verschiedene
                        Kanäle, wie E-Mail-Verkehr, Anrufprotokolle, Nachrichten
                        in sozialen Netzwerken und Chat-Verläufe, inklusive der
                        beteiligten Personen, Zeitstempel und Übertragungswege.
                        Verfahrensdaten beschreiben die Prozesse und Abläufe
                        innerhalb von Systemen oder Organisationen,
                        einschließlich Workflow-Dokumentationen, Protokolle von
                        Transaktionen und Aktivitäten, sowie Audit-Logs, die zur
                        Nachverfolgung und Überprüfung von Vorgängen verwendet
                        werden.{" "}
                    </li>
                    <li>
                        <strong>Nutzungsdaten:</strong> Nutzungsdaten beziehen
                        sich auf Informationen, die erfassen, wie Nutzer mit
                        digitalen Produkten, Dienstleistungen oder Plattformen
                        interagieren. Diese Daten umfassen eine breite Palette
                        von Informationen, die aufzeigen, wie Nutzer Anwendungen
                        nutzen, welche Funktionen sie bevorzugen, wie lange sie
                        auf bestimmten Seiten verweilen und über welche Pfade
                        sie durch eine Anwendung navigieren. Nutzungsdaten
                        können auch die Häufigkeit der Nutzung, Zeitstempel von
                        Aktivitäten, IP-Adressen, Geräteinformationen und
                        Standortdaten einschließen. Sie sind besonders wertvoll
                        für die Analyse des Nutzerverhaltens, die Optimierung
                        von Benutzererfahrungen, das Personalisieren von
                        Inhalten und das Verbessern von Produkten oder
                        Dienstleistungen. Darüber hinaus spielen Nutzungsdaten
                        eine entscheidende Rolle beim Erkennen von Trends,
                        Vorlieben und möglichen Problembereichen innerhalb
                        digitaler Angebote{" "}
                    </li>
                    <li>
                        <strong>Personenbezogene Daten:</strong>{" "}
                        "Personenbezogene Daten" sind alle Informationen, die
                        sich auf eine identifizierte oder identifizierbare
                        natürliche Person (im Folgenden "betroffene Person")
                        beziehen; als identifizierbar wird eine natürliche
                        Person angesehen, die direkt oder indirekt, insbesondere
                        mittels Zuordnung zu einer Kennung wie einem Namen, zu
                        einer Kennnummer, zu Standortdaten, zu einer
                        Online-Kennung (z. B. Cookie) oder zu einem oder
                        mehreren besonderen Merkmalen identifiziert werden kann,
                        die Ausdruck der physischen, physiologischen,
                        genetischen, psychischen, wirtschaftlichen, kulturellen
                        oder sozialen Identität dieser natürlichen Person sind.{" "}
                    </li>
                    <li>
                        <strong>Protokolldaten:</strong> Protokolldaten sind
                        Informationen über Ereignisse oder Aktivitäten, die in
                        einem System oder Netzwerk protokolliert wurden. Diese
                        Daten enthalten typischerweise Informationen wie
                        Zeitstempel, IP-Adressen, Benutzeraktionen,
                        Fehlermeldungen und andere Details über die Nutzung oder
                        den Betrieb eines Systems. Protokolldaten werden oft zur
                        Analyse von Systemproblemen, zur Sicherheitsüberwachung
                        oder zur Erstellung von Leistungsberichten verwendet.{" "}
                    </li>
                    <li>
                        <strong>Verantwortlicher:</strong> Als
                        "Verantwortlicher" wird die natürliche oder juristische
                        Person, Behörde, Einrichtung oder andere Stelle, die
                        allein oder gemeinsam mit anderen über die Zwecke und
                        Mittel der Verarbeitung von personenbezogenen Daten
                        entscheidet, bezeichnet.{" "}
                    </li>
                    <li>
                        <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder
                        mit oder ohne Hilfe automatisierter Verfahren
                        ausgeführte Vorgang oder jede solche Vorgangsreihe im
                        Zusammenhang mit personenbezogenen Daten. Der Begriff
                        reicht weit und umfasst praktisch jeden Umgang mit
                        Daten, sei es das Erheben, das Auswerten, das Speichern,
                        das Übermitteln oder das Löschen.{" "}
                    </li>
                    <li>
                        <strong>Vertragsdaten:</strong> Vertragsdaten sind
                        spezifische Informationen, die sich auf die
                        Formalisierung einer Vereinbarung zwischen zwei oder
                        mehr Parteien beziehen. Sie dokumentieren die
                        Bedingungen, unter denen Dienstleistungen oder Produkte
                        bereitgestellt, getauscht oder verkauft werden. Diese
                        Datenkategorie ist wesentlich für die Verwaltung und
                        Erfüllung vertraglicher Verpflichtungen und umfasst
                        sowohl die Identifikation der Vertragsparteien als auch
                        die spezifischen Bedingungen und Konditionen der
                        Vereinbarung. Vertragsdaten können Start- und Enddaten
                        des Vertrages, die Art der vereinbarten Leistungen oder
                        Produkte, Preisvereinbarungen, Zahlungsbedingungen,
                        Kündigungsrechte, Verlängerungsoptionen und spezielle
                        Bedingungen oder Klauseln umfassen. Sie dienen als
                        rechtliche Grundlage für die Beziehung zwischen den
                        Parteien und sind entscheidend für die Klärung von
                        Rechten und Pflichten, die Durchsetzung von Ansprüchen
                        und die Lösung von Streitigkeiten.{" "}
                    </li>
                    <li>
                        <strong>Zahlungsdaten:</strong> Zahlungsdaten umfassen
                        sämtliche Informationen, die zur Abwicklung von
                        Zahlungstransaktionen zwischen Käufern und Verkäufern
                        benötigt werden. Diese Daten sind von entscheidender
                        Bedeutung für den elektronischen Handel, das
                        Online-Banking und jede andere Form der finanziellen
                        Transaktion. Sie beinhalten Details wie
                        Kreditkartennummern, Bankverbindungen, Zahlungsbeträge,
                        Transaktionsdaten, Verifizierungsnummern und
                        Rechnungsinformationen. Zahlungsdaten können auch
                        Informationen über den Zahlungsstatus, Rückbuchungen,
                        Autorisierungen und Gebühren enthalten.{" "}
                    </li>
                </ul>
                <p class="seal">
                    <a
                        href="https://datenschutz-generator.de/"
                        title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                    >
                        Erstellt mit kostenlosem Datenschutz-Generator.de von
                        Dr. Thomas Schwenke
                    </a>
                </p>
            </div>
        </>
    );
}

export default Main;
